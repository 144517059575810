(function(window, $, undefined) {
  window.popUp = function(page, PWidth, PHeight, id) {
    window.open(
      page,
      'Help_Page_' + id,
      'toolbar=0,scrollbars=1,location=yes,status=0,menubars=0,resizable=1,width=' +
        PWidth +
        ',height=' +
        PHeight
    );
  };

  $(document).ready(function() {
    var substringMatcher = function(strs) {
      return function findMatches(q, cb) {
        var matches, substringRegex;

        // an array that will be populated with substring matches
        matches = [];

        // regex used to determine if a string contains the substring `q`
        substrRegex = new RegExp(q, 'i');

        // iterate through the pool of strings and for any string that
        // contains the substring `q`, add it to the `matches` array
        $.each(strs, function(i, str) {
          if (substrRegex.test(str.name)) {
            matches.push(str);
          }
        });

        cb(matches);
      };
    };

    var products = [
      { id: 'association-decals.asp', name: 'Association Decals' },
      { id: 'automotive-sales.asp', name: 'Automotive Products' },
      { id: 'oil-change-decals.asp', name: 'Automotive Service Stickers' },
      { id: 'banners.asp', name: 'Banners' },
      { id: 'brewery-decals.asp', name: 'Brewery Decals' },
      { id: 'bumper-stickers.asp', name: 'Bumper Stickers' },
      { id: 'business-card-magnets.asp', name: 'Business Card Magnets' },
      { id: 'calendar-magnets.asp', name: 'Calendar Magnets' },
      { id: 'election-campaign-products.asp', name: 'Campaign Supplies' },
      { id: 'car-magnets.asp', name: 'Car Magnets' },
      {
        id: 'standard-shapes-sizes.asp',
        value: 'Standard Magnets',
      },
      {
        id: 'Chamber-of-Commerce-Decals.asp',
        name: 'Chamber of Commerce Decals',
      },
      { id: 'chrome-decals.asp', name: 'Chrome Decals' },
      { id: 'church-decals.asp', name: 'Church Decals' },
      { id: 'circular-and-oval-decals.asp', name: 'Circular and Oval Decals' },
      { id: 'Coasters.asp', name: 'Coasts' },
      { id: 'cork-coasters.asp', name: 'Cork Coasters' },
      { id: 'corrugated-plastic-signs.asp', name: 'Corrugated Plastic Signs' },
      { id: 'custom-clear-decals.asp', name: 'Custom Clear Decals' },
      { id: 'Custom-coasters.asp', name: 'Custom Coasters' },
      { id: 'custom-decals.asp', name: 'Custom Decals' },
      { id: 'custom-removable-decals.asp', name: 'Custom Removable Decals' },
      { id: 'custom-vinyl-decals.asp', name: 'Custom Vinyl Decals' },
      { id: 'destructible-decals.asp', name: 'Destructible Decals' },
      { id: 'domed-decals.asp', name: 'Domed Decals' },
      { id: 'double-sided-decals.asp', name: 'Double Sided Decals' },
      { id: 'floor-decals.asp', name: 'Floor Decals' },
      { id: 'freezer-door-decals.asp', name: 'Freezer Door Decals' },
      { id: 'full-color-decals.asp', name: 'Full Color Decals' },
      { id: 'glow-in-the-dark-decals.asp', name: 'Glow in the Dark Decals' },
      { id: 'graphic-design.asp', name: 'Graphic Design' },
      { id: 'heavy-duty-decals.asp', name: 'Heavy Duty Decals' },
      { id: 'Keg-Collars-and-Wraps.asp', name: 'Keg Collars and Wraps' },
      { id: 'labels.asp', name: 'Labels' },
      { id: 'large-window-decals.asp', name: 'Large Store Window Decals' },
      { id: 'leather-coasters.asp', name: 'Leather Coasters' },
      { id: 'graphic-design.asp', name: 'Logo Design' },
      { id: 'magnets.asp', name: 'Magnets' },
      { id: 'oilfield-Decals-Stickers.asp', name: 'Oilfield Decals' },
      { id: 'oval-decals.asp', name: 'Oval Decals' },
      { id: 'paperboard-coasters.asp', name: 'Paperboard Coasters' },
      { id: 'parking-permits.asp', name: 'Parking Permits' },
      {
        id: 'quick-ship-bumper-stickers.asp',
        name: 'Quick Ship Bumper Stickers',
      },
      { id: 'reflective-decals.asp', name: 'Reflective Decals' },
      { id: 'school-products.asp', name: 'School Products' },
      { id: 'graphic-design.asp', name: 'Search Engine Optimization' },
      { id: 'security-decals.asp', name: 'Security Decals' },
      { id: 'security-signs.asp', name: 'Security Yard Signs' },
      { id: 'signs-and-posters.asp', name: 'Signs and Posters' },
      { id: 'square-decals.asp', name: 'Square Decals' },
      { id: 'static-cling-decals.asp', name: 'Static Cling Decals' },
      { id: 'super-adhesive-decals.asp', name: 'Super Adhesive Decals' },
      { id: 't-shirts.asp', name: 'T-Shirts' },
      { id: 'vinyl-bumper-stickers.asp', name: 'Vinyl Bumper Stickers' },
      { id: 'vinyl-lettering.asp', name: 'Vinyl Lettering' },
      { id: 'yellow-dot-decals.asp', name: 'Yellow Dot Decals' },
    ];

    function handleTypeaheadSubmit(evt, item) {
      window.location.href = item.id;
      try {
        ga('send', 'event', 'product-search', 'search', item.name);
      } catch (error) {
        console.error(error);
      }
    }

    $('#search_bar')
      .typeahead(
        {
          hint: true,
          highlight: true,
          minLength: 1,
        },
        {
          name: 'products',
          source: substringMatcher(products),
          display: 'name',
        }
      )
      .on('typeahead:selected', handleTypeaheadSubmit)
      .on('typeahead:autocomplete', handleTypeaheadSubmit);

    $('#00N80000002emH3')
      .typeahead(
        {
          hint: true,
          highlight: true,
          minLength: 1,
        },
        {
          name: 'products',
          source: substringMatcher(products),
          display: 'name',
        }
      )
      .on('keyup', this, function(event) {
        if (event.keyCode === 13) {
          $(this).blur();
        }
      });

    $('a[data-action="toggle-search"]').on('click', function(e) {
      $('.search-section').toggleClass('d-none');
    });
  });
})(this, $);
