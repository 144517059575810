(function(window, $) {
  $(document).ready(function() {
    $('.btn-quote').on('click', function() {
      ga('send', 'event', 'button', 'click', 'get quote');
    });

    $('.contact-submit').on('click', function() {
      ga('send', 'event', 'button', 'click', 'contact submit');
    });

    $('.quote-submit').on('click', function() {
      ga('send', 'event', 'button', 'click', 'quote submit');
    });

    $('.hamburger').on('click', function() {
      ga('send', 'event', 'button', 'click', 'hamburger');
    });
  });
})(window, $);
