(function(window, $, undefined) {
  $(document).ready(function() {
    var handleInputErrorState = function(e) {
      var $this = $(this);

      if ($this.val()) {
        $this.removeClass('border-danger');
      } else {
        $this.addClass('border-danger');
      }
    };

    var form = $('.contact-form');

    form.on('submit', handleContactFormSubmit);

    function handleContactFormSubmit(event) {
      var $name = $('input[id="last_name"]');
      var $phone = $('input[name="phone"]');
      var $email = $('input[name="email"]');

      $name.off('keyup', handleInputErrorState);
      $phone.off('keyup', handleInputErrorState);
      $email.off('keyup', handleInputErrorState);

      $name.removeClass('border-danger');
      $phone.removeClass('border-danger');
      $email.removeClass('border-danger');

      if ($name.length && !$name.val()) {
        event.preventDefault();

        alert('Please provide your name.');

        $name.addClass('border-danger');
        $name.focus();
        $name.on('keyup', handleInputErrorState);
      } else if (!$phone.val() && !$email.val()) {
        event.preventDefault();

        alert('Please provide your phone number or email address.');

        $phone.addClass('border-danger');
        $phone.on('keyup', handleInputErrorState);

        $email.addClass('border-danger');
        $email.on('keyup', handleInputErrorState);
        $email.focus();
      }
    }
  });
})(this, $);
