/* https://github.com/peledies/google-places */
(function($) {
  $.googlePlaces = function(element, options) {
    var defaults = {
      placeId: 'ChIJN1t_tDeuEmsRUsoyG83frY4', // placeId provided by google api documentation
      render: ['reviews'],
      min_rating: 0,
      max_rows: 0,
      rotateTime: false,
    };

    var plugin = this;

    plugin.settings = {};

    var $element = $(element),
      element = element;

    plugin.init = function() {
      plugin.settings = $.extend({}, defaults, options);
      $element.html("<div id='map-plug'></div>"); // create a plug for google to load data into
      initialize_place(function(place) {
        plugin.place_data = place;
        // render specified sections
        if (plugin.settings.render.indexOf('reviews') > -1) {
          renderReviews(plugin.place_data.reviews);
          if (!!plugin.settings.rotateTime) {
            initRotation();
          }
        }
      });
    };

    var initialize_place = function(c) {
      var map = new google.maps.Map(document.getElementById('map-plug'));

      var request = {
        placeId: plugin.settings.placeId,
      };

      var service = new google.maps.places.PlacesService(map);

      service.getDetails(request, function(place, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          c(place);
        }
      });
    };

    var sort_by_date = function(ray) {
      ray.sort(function(a, b) {
        var keyA = new Date(a.time),
          keyB = new Date(b.time);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      return ray;
    };

    var filter_minimum_rating = function(reviews) {
      for (var i = reviews.length - 1; i >= 0; i--) {
        if (reviews[i].rating < plugin.settings.min_rating) {
          reviews.splice(i, 1);
        }
      }
      return reviews;
    };

    // <div class="item-review" itemscope itemtype="http://schema.org/Review">
    // 	<a itemprop="url" href="http://www.123decal.com/static-cling-decals.asp"><div itemprop="name"><strong>Static Cling Decals</strong></div></a>
    // 	<div itemprop="reviewBody">Thanks so much!  The clings turned out fantastic!  We LOVE them.</div>
    // 	<div itemprop="author" itemscope itemtype="http://schema.org/Person">
    // 		Written by: <span itemprop="name">Marcy S.</span>
    // 	</div>
    // 	<div itemprop="itemReviewed" itemscope itemtype="http://schema.org/Thing">
    // 		<span itemprop="name">Static Cling Decals</span>
    // 	</div>
    // 	<div><meta itemprop="datePublished" content="2012-11-15">Date published: 11/15/2012</div>
    // </div>

    var renderReviews = function(reviews) {
      reviews = sort_by_date(reviews);
      reviews = filter_minimum_rating(reviews);
      var html = '';
      var row_count =
        plugin.settings.max_rows > 0
          ? plugin.settings.max_rows - 1
          : reviews.length - 1;
      // make sure the row_count is not greater than available records
      row_count =
        row_count > reviews.length - 1 ? reviews.length - 1 : row_count;
      for (var i = row_count; i >= 0; i--) {
        var stars = renderStars(reviews[i].rating);
        var date = convertTime(reviews[i].time);
        html +=
          '<div class="item-review" itemscope itemtype="http://schema.org/Review">';

        html += stars;

        html += '<div itemprop="reviewBody">';
        html += reviews[i].text;
        html += '</div>';

        html +=
          '<div itemprop="author" itemscope itemtype="http://schema.org/Person">';
        html += '<span itemprop="name">' + reviews[i].author_name + '</span>';
        html += '</div>';

        html +=
          '<div><meta itemprop="datePublished" content="' +
          date +
          '">' +
          date +
          '</div>';

        html += '</div>';
      }
      $element.append(html);
    };

    var initRotation = function() {
      var $reviewEls = $element.children('.item-review');
      var currentIdx = $reviewEls.length > 0 ? 0 : false;
      $reviewEls.hide();
      if (currentIdx !== false) {
        $($reviewEls[currentIdx]).show();
        setInterval(function() {
          if (++currentIdx >= $reviewEls.length) {
            currentIdx = 0;
          }
          $reviewEls.hide();
          $($reviewEls[currentIdx]).fadeIn('slow');
        }, plugin.settings.rotateTime);
      }
    };

    var renderStars = function(rating) {
      var stars = '<div class="review-stars">';

      // fill in gold stars
      for (var i = 0; i < rating; i++) {
        stars = stars + "<i class='fa fa-star'></i>";
      }

      // fill in empty stars
      if (rating < 5) {
        for (var i = 0; i < 5 - rating; i++) {
          stars = stars + "<i class='fa fa-star text-muted'></i>";
        }
      }
      stars = stars + '</div>';
      return stars;
    };

    var convertTime = function(UNIX_timestamp) {
      var a = new Date(UNIX_timestamp * 1000);
      var time = a.getMonth() + 1 + '/' + a.getDate() + '/' + a.getFullYear();
      return time;
    };

    plugin.init();
  };

  $.fn.googlePlaces = function(options) {
    return this.each(function() {
      if (undefined == $(this).data('googlePlaces')) {
        var plugin = new $.googlePlaces(this, options);
        $(this).data('googlePlaces', plugin);
      }
    });
  };
})(jQuery);
