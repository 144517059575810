(function(window, $) {
  $(document).ready(function() {
    if ($('.product-slideshow').length) {
      $('.product-slideshow').slick({
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        // prevArrow: '<',
        // nextArrow: '>',
      });
    }
  });
})(window, $);
